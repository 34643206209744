import View from './View';

class viewMap extends View {
  _parentEl = document.getElementById('map');
  _map;

  _btnDirections = document.querySelector('.btn-directions');

  addHandler(handler) {
    window.addEventListener('load', handler);
  }

  setView(coords) {
    this._map = L.map('map').setView([coords.lat, coords.lon], 13);

    L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
      maxZoom: 20,
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
    }).addTo(this._map);

    L.marker([coords.lat, coords.lon])
      .addTo(this._map)
      .bindPopup('PRO TILING DESIGN', {
        closeOnClick: false,
        autoClose: false,
      })
      .openPopup();
  }

  addBtnDirectionHandler(handler) {
    this._btnDirections.addEventListener('click', e => {
      e.preventDefault();
      handler();
    });
  }
}

export default new viewMap();
