import { TIMEOUT_SEC } from './config';

const timeout = function (s) {
  return new Promise(function (_, error) {
    setTimeout(function () {
      return error(`Request took too long, timeout after ${s} seconds`);
    }, s);
  });
};

export const AJAX = async function (url, uploadData = undefined) {
  try {
    const fetchPro =
      uploadData === true
        ? await fetch(url, {
            method: POST,
            headers: { 'Content-Type': 'appication/json' },
            body: JSON.stringify(uploadData),
          })
        : fetch(url);

    const res = await Promise.race([fetchPro, timeout(TIMEOUT_SEC)]);

    const data = await res.json();

    if (!res.ok) throw new Error(`${data.message} (${res.status})`);

    return data;
  } catch (err) {
    throw err;
  }
};
