import View from './View.js';

class smoothScrollingView extends View {
  constructor() {
    super();
    this._addEventListeners();
  }
  _parentEl = document.querySelector('.main-nav-list');
  // _footerLink = document.querySelector('.footer-link');
  _logoImg = document.querySelectorAll('.logo-img');
  _header = document.querySelector('.header');

  _scrollToSection(targetSection) {
    document.querySelector(`.${targetSection}`).scrollIntoView({
      behavior: 'smooth',
    });
  }

  _handleClick(e) {
    e.preventDefault();
    const clicked = e.target.closest('.nav-link');

    if (clicked) {
      const targetSection = clicked.dataset.section;

      this._scrollToSection(targetSection);
      this._header.classList.remove('nav-open');
    } else if (e.target.classList.contains('logo-img')) {
      const targetSection = e.target.dataset.section;
      this._scrollToSection(targetSection);
    }
  }

  _addEventListeners() {
    this._parentEl.addEventListener('click', this._handleClick.bind(this));

    this._logoImg.forEach(logo =>
      logo.addEventListener('click', this._handleClick.bind(this))
    );
  }
}
export default new smoothScrollingView();
