import View from './View';

class viewForm extends View {
  _parentEl = document.forms['contact-form'];
  formMsg = this._parentEl.querySelector('.form-msg-container');

  addFormSubmitHandler(handler) {
    this._parentEl.addEventListener('submit', e => {
      e.preventDefault();
      const formData = new FormData(this._parentEl);
      handler(formData);
    });
  }

  formReset() {
    this._parentEl.reset();
  }
}

export default new viewForm();
