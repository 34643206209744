import { TIMEOUT_SEC } from './config';
import * as model from './model';
import viewForm from './views/viewForm';
import viewHeroSlider from './views/viewHeroSlider';
import viewMap from './views/viewMap';
import viewSliderComponent from './views/viewSliderComponent';

import viewSmoothScrolling from './views/viewSmoothScrolling.js';
import viewTabbedComp from './views/viewTabbedComp';

// Hero slider controller

const controlHeroSlider = function () {
  const sliderImgs = viewHeroSlider.getSliderImgs();

  model.initializeImages(sliderImgs);

  function startSliderLoop() {
    model.updateCounter(performance.now());
    viewHeroSlider.updateSliderBackground(
      model.state.heroSlider.imagesSrc[model.state.heroSlider.counter]
    );
    requestAnimationFrame(startSliderLoop);
  }

  startSliderLoop();
};

// Tabbed component controller

const controlTabs = function (clickedTab) {
  model.setActiveTab(clickedTab);
  viewTabbedComp.setActiveTab(model.state.clickedTab);
  viewTabbedComp.setContentTab(model.state.contentTab);
};

// Slider Component Controller

function controlSlider() {
  const maxSlides = viewSliderComponent.getSlides();
  model.setMaxSlides(maxSlides);

  const dotHandler = dataSlide => {
    model.dotSlide(dataSlide);
    viewSliderComponent.updateSlide(model.state.sliderComponent.curSlide);
  };
  viewSliderComponent.addDotListener(dotHandler);

  const updateSlide = offset => {
    model.updateSlide(offset);
    viewSliderComponent.updateSlide(model.state.sliderComponent.curSlide);
  };

  viewSliderComponent.addSlideBtnListeners(updateSlide);

  viewSliderComponent.updateSlide(model.state.sliderComponent.curSlide);
}

// Control form

const controlForm = async function (formData) {
  try {
    viewForm.displaySpinner(viewForm.formMsg);

    await model.uploadForm(formData);

    viewForm.displayMessage(viewForm.formMsg, 'Message succesfully sent');

    setTimeout(function () {
      viewForm.clear(viewForm.formMsg);
    }, TIMEOUT_SEC);

    viewForm.formReset();
  } catch (err) {
    viewForm.displayError(viewForm.formMsg, 'Message failed to send');

    setTimeout(function () {
      viewForm.clear(viewForm.formMsg);
    }, TIMEOUT_SEC);

    viewForm.formReset();
  }
};

// Map controller

const mapController = async function () {
  try {
    await model.mapInit();

    viewMap.setView(model.state.map.coords);

    viewMap.addBtnDirectionHandler(model.openGoogleMaps);
  } catch (err) {
    viewMap.displayError();
  }
};

const init = function () {
  viewHeroSlider.addHandler(controlHeroSlider);

  viewTabbedComp.addHandlerTabClick(controlTabs);

  viewSliderComponent.addHandler(controlSlider);

  viewForm.addFormSubmitHandler(controlForm);

  viewMap.addHandler(mapController);
};

init();
