import View from './View';

class viewSliderComponent extends View {
  _parentEl = document.querySelector('.slider');
  _dotContainer = this._parentEl.querySelector('.dot-container');

  constructor() {
    super();
    this.createDots();
  }

  addHandler(handler) {
    window.addEventListener('load', handler);
  }

  getSlides() {
    return this._parentEl.querySelectorAll('.slide');
  }

  updateSlide(curSlide) {
    const slides = this.getSlides();
    slides.forEach((slide, index) => {
      slide.style.transform = `translateX(${(index - curSlide) * 100}%)`;
    });
    this.activateDots(curSlide);
  }

  addSlideBtnListeners(updateSlide) {
    this._parentEl
      .querySelector('.slider-btn--right')
      .addEventListener('click', () => updateSlide(1));
    this._parentEl
      .querySelector('.slider-btn--left')
      .addEventListener('click', () => updateSlide(-1));

    document.addEventListener('keydown', e => {
      if (e.key === 'ArrowRight') {
        updateSlide(1);
      } else {
        if (e.key === 'ArrowLeft') updateSlide(-1);
      }
    });
  }

  addDotListener(updateDot) {
    this._dotContainer.addEventListener('click', e => {
      const dot = e.target.closest('.dot');

      if (!dot) return;
      const dataSlide = dot.dataset.slide;

      updateDot(+dataSlide);
    });
  }

  createDots() {
    const slides = this.getSlides();
    slides.forEach((_, i) => {
      const dot = `<button class="dot" data-slide="${i}"></button>`;
      this._dotContainer.insertAdjacentHTML('beforeend', dot);
    });
  }

  activateDots(curSlide) {
    const allDots = this._dotContainer.querySelectorAll('.dot');
    allDots.forEach((dot, i) => {
      if (i === curSlide) {
        dot.classList.add('dot--fill');
      } else {
        dot.classList.remove('dot--fill');
      }
    });
  }
}

export default new viewSliderComponent();
