import View from './View';

class viewTabbedComponent extends View {
  _parentEl = document.querySelector('.about-box');

  addHandlerTabClick(handler) {
    this._parentEl.addEventListener('click', e => {
      e.preventDefault();
      this.openServicesList(e);

      const clickedTab = e.target.closest('.btn-tab');
      if (!clickedTab) return;

      handler(clickedTab);
    });
  }

  setActiveTab(clickedTab) {
    const tabs = this._parentEl.querySelectorAll('.btn-tab');
    tabs.forEach(tab => {
      tab.classList.toggle('btn-tab--active', tab === clickedTab);
    });
  }

  setContentTab(contentTab) {
    console.log(contentTab);
    const tabContent = document.querySelectorAll('.btn-tab-content');
    tabContent.forEach(tabContent =>
      tabContent.classList.remove('tab-content--active')
    );
    document
      .querySelector(`.${contentTab}`)
      .classList.add('tab-content--active');

    if (contentTab !== 'tab-content--2') {
      this._parentEl.querySelector('.services').classList.remove('list-open');
    }
  }

  openServicesList(e) {
    if (e.target.classList.contains('full-services-link')) {
      this._parentEl.querySelector('.services').classList.add('list-open');
    }
    if (e.target.classList.contains('close-icon')) {
      this._parentEl.querySelector('.services').classList.remove('list-open');
    }
  }
}

export default new viewTabbedComponent();
