import { scriptURL, TIMER_SEC, REGADDRESS } from './config';
import { AJAX } from './helpers.js';

export const state = {
  heroSlider: {
    counter: 1,
    timer: TIMER_SEC,
    lastTime: 0,
    imagesSrc: [],
  },
  tabbedComponent: { activeTab: null, contentTab: '' },

  sliderComponent: {
    curSlide: 0,
    dotDataSlide: 0,
    maxSlide: null,
  },
  map: {
    coords: {
      lat: null,
      lon: null,
    },
  },
};

export function initializeImages(imgList) {
  state.heroSlider.imagesSrc = Array.from(imgList, img =>
    img.getAttribute('src')
  );
}

export function updateCounter(currentTime) {
  const deltaTime = currentTime - state.heroSlider.lastTime;
  if (deltaTime >= state.heroSlider.timer) {
    state.heroSlider.counter =
      (state.heroSlider.counter + 1) % state.heroSlider.imagesSrc.length;
    state.heroSlider.lastTime = currentTime;
  }
}

export function setActiveTab(clickedTab) {
  state.clickedTab = clickedTab;
  state.contentTab = clickedTab.dataset.content;
}

// Slider model

export const setMaxSlides = function (maxSlides) {
  state.sliderComponent.maxSlide = maxSlides.length - 1;
};

export const dotSlide = dataSlide => {
  console.log(dataSlide);
  state.sliderComponent.curSlide = dataSlide;

  return state.sliderComponent.curSlide;
};

export const updateSlide = offset => {
  state.sliderComponent.curSlide =
    (state.sliderComponent.curSlide + offset) %
    (state.sliderComponent.maxSlide + 1);
  if (state.sliderComponent.curSlide < 0) {
    state.sliderComponent.curSlide = state.sliderComponent.maxSlide;
  }

  return state.sliderComponent.curSlide;
};

// Form model

export const uploadForm = async function (formData) {
  try {
    const data = fetch(scriptURL, {
      method: 'POST',
      body: formData,
    });
    const res = await data;
    console.log(res);
  } catch (err) {
    throw err;
  }
};

// Map model

export const mapInit = async function () {
  try {
    const data = await AJAX(
      `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
        REGADDRESS
      )}&format=json`
    );

    const [lat, lon] = [data[0].lat, data[0].lon];

    state.map.coords.lat = lat;
    state.map.coords.lon = lon;
  } catch (err) {
    throw err;
  }
};

export const openGoogleMaps = function () {
  const destination = encodeURIComponent(REGADDRESS);

  const googleMapsURL = `https://www.google.com/maps/dir//${destination}`;
  window.open(googleMapsURL, '_blank');
};
