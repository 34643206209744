export default class View {
  _data;
  _body = document.body;
  _allSections = document.querySelectorAll('.section');
  _header = document.querySelector('.header');
  _sectionHero = document.querySelector('.section-hero');
  _headerHeight = this._header.getBoundingClientRect().height;
  _btnMobileNav = document.querySelector('.btn-mobile-nav');

  constructor() {
    this.callObs();
    this.callNavObs();
    this.addNavMobileListener();
  }

  // Reusable methods

  clear(parentEl) {
    parentEl.innerHTML = '';
  }

  displaySpinner = function (parentEl) {
    const spinnerEl = `
      <div class="spinner-container">
        <div class="spinner"></div>
      </div>
    `;

    this.clear(parentEl);
    parentEl.insertAdjacentHTML('afterbegin', spinnerEl);
  };

  displayMessage(parentEl, message = '') {
    const messageEl = `<div class="message"><ion-icon class="msg-icon" name="paper-plane-outline"></ion-icon>
    <span>${message}</span>
  </div>`;

    this.clear(parentEl);
    parentEl.insertAdjacentHTML('afterbegin', messageEl);
  }

  displayError(parentEl, errorMessage = 'An error occured') {
    const errorEl = `
    <div class="error"><ion-icon class="msg-icon" name="alert-circle-outline"></ion-icon>
      <span>${errorMessage}</span>
    </div>
    `;

    this.clear(parentEl);
    parentEl.insertAdjacentHTML('afterbegin', errorEl);
  }

  // Sticky Nav

  heroSectionObs(entries) {
    const [entry] = entries;

    this._body.classList.toggle('sticky', !entry.isIntersecting);
  }

  headerObs() {
    return new IntersectionObserver(this.heroSectionObs.bind(this), {
      root: null,
      threshold: 0.2,
      rootMargin: `-${this._headerHeight}px`,
    });
  }

  callNavObs() {
    this.headerObs().observe(this._sectionHero);
  }

  // Reveal sections

  revealSection(entries, observer) {
    entries.forEach(entry => {
      if (!entry.isIntersecting) return;

      entry.target.classList.remove('section--hidden');
      observer.unobserve(entry.target);
    });
  }

  sectionObs() {
    return new IntersectionObserver(this.revealSection.bind(this), {
      root: null,
      threshold: 0.2,
    });
  }

  callObs() {
    const observer = this.sectionObs();

    this._allSections.forEach(section => {
      section.classList.add('section--hidden');
      observer.observe(section);
    });
  }

  addNavMobileListener() {
    this._btnMobileNav.addEventListener('click', e => {
      e.preventDefault();
      const menuIcon = e.target.closest(
        '.icon-mobile-nav[name="menu-outline"]'
      );
      const closeIcon = e.target.closest(
        '.icon-mobile-nav[name="close-outline"]'
      );

      if (menuIcon) {
        this._header.classList.add('nav-open');
      } else if (closeIcon) {
        this._header.classList.remove('nav-open');
      }
    });
  }
}
