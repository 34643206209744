import View from './View.js';

class viewHeroSlider extends View {
  _parentEl = document.querySelector('.hero-slider');
  _message = '';
  _error = 'Images could not be loaded';

  addHandler(handler) {
    window.addEventListener('load', handler);
  }

  getSliderImgs() {
    return this._parentEl.querySelectorAll('.hero-slider-img');
  }

  updateSliderBackground(imageUrl) {
    this._parentEl.style.backgroundImage = `url(${imageUrl})`;
  }
}

export default new viewHeroSlider();
